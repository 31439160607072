import React from 'react';
import styled from 'styled-components';
import fallbackImage from '../assets/shield_none-uVtR8SvS.png'; // Import the fallback image
import { LossIcon } from '../assets/LossIcon';
import { DrawIcon } from '../assets/DrawIcon';
import { WinIcon } from '../assets/WinIcon';
import { Arrow } from '../assets/Arrow';
import PlayerSkeleton from './PlayerSkeleton';
import { Game, Player } from '../App'; // Import the types from App
import PlayerComponent from './Player';

const TeamPlayersContainer = styled.div`
  flex: 1;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 5px; /* Reduce padding on mobile */
  }
`;

const PlayersGrid = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack the teams vertically on mobile */
    gap: 10px; /* Reduce gap between teams */
  }
`;

const TeamTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 10px;
  font-size: 18px; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  color: #ffcc00; /* Change to a bright, noticeable color */
  text-transform: uppercase; /* Make the text uppercase */
  text-align: center; /* Center the title */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add a subtle shadow for depth */
  padding: 5px 0; /* Add some padding for spacing */
  border-bottom: 2px solid #ffcc00; /* Add a bottom border to highlight the title */

  h3 {
    margin: 0;
  }

  .nameBadge {
    display: flex;
    gap: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 768px) {
    font-size: 16px; /* Reduce font size slightly on mobile */
    margin-bottom: 16px;
  }
`;

const LastGames = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  span {
    display: flex;
    align-items: center;
  }
`;

interface PlayerListProps {
  players: Player[] | null;
  selectedGame: Game | null;
  limitValue: string;
  isLoading: boolean;
}

const PlayerList: React.FC<PlayerListProps> = ({
  players,
  selectedGame,
  limitValue,
  isLoading,
}) => {
  if (!selectedGame) {
    return <div>Select a game to view players.</div>;
  }

  const homeTeamSlug = selectedGame?.game.homeTeam.slug;
  const awayTeamSlug = selectedGame?.game.awayTeam.slug;

  const homeTeamPlayers = players?.filter(
    (player) =>
      player.player.activeClub?.slug === homeTeamSlug ||
      player.player.activeNationalTeam?.slug === homeTeamSlug
  );

  const awayTeamPlayers = players?.filter(
    (player) =>
      player.player.activeClub?.slug === awayTeamSlug ||
      player.player.activeNationalTeam?.slug === awayTeamSlug
  );

  return (
    <PlayersGrid>
      <TeamPlayersContainer>
        <TeamTitle>
          <span className="nameBadge">
            <h3>{selectedGame.game.homeTeam.shortName}</h3>
            <img
              loading="lazy"
              src={selectedGame.game.homeTeam.pictureUrl || fallbackImage}
              alt={`${selectedGame.game.homeTeam.name} logo`}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = fallbackImage;
              }}
            />
          </span>
          <LastGames>
            {selectedGame.game.homeTeam.lastFiveGames.map((lastGame, index) => {
              if (lastGame.winner?.slug === selectedGame.game.homeTeam.slug) {
                return <WinIcon key={index} />;
              } else if (!lastGame.winner?.slug) {
                return <DrawIcon key={index} />;
              } else {
                return <LossIcon key={index} />;
              }
            })}
            <span>
              <Arrow />
            </span>
          </LastGames>
        </TeamTitle>
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <PlayerSkeleton key={index} />
            ))
          : homeTeamPlayers?.map((player) => (
              <PlayerComponent
                limitValue={limitValue}
                player={player}
                key={player.player.id}
              />
            ))}
      </TeamPlayersContainer>

      <TeamPlayersContainer>
        <TeamTitle>
          <span className="nameBadge">
            <h3>{selectedGame.game.awayTeam.shortName}</h3>
            <img
              loading="lazy"
              src={selectedGame.game.awayTeam.pictureUrl || fallbackImage}
              alt={`${selectedGame.game.awayTeam.name} logo`}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = fallbackImage;
              }}
            />
          </span>
          <LastGames>
            {selectedGame.game.awayTeam.lastFiveGames.map((lastGame, index) => {
              if (lastGame.winner?.slug === selectedGame.game.awayTeam.slug) {
                return <WinIcon key={index} />;
              } else if (!lastGame.winner?.slug) {
                return <DrawIcon key={index} />;
              } else {
                return <LossIcon key={index} />;
              }
            })}
            <span>
              <Arrow />
            </span>
          </LastGames>
        </TeamTitle>
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <PlayerSkeleton key={index} />
            ))
          : awayTeamPlayers?.map((player) => (
              <PlayerComponent
                limitValue={limitValue}
                player={player}
                key={player.player.id}
              />
            ))}
      </TeamPlayersContainer>
    </PlayersGrid>
  );
};

export default PlayerList;
