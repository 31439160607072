import React from 'react';
import styled from 'styled-components';
import BuyMeABeerButton from './BuyMeABeerButton';

// Styled component for the header container
const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #333;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding: 15px; /* Reduce padding on mobile */
  }
`;

// Styled component for the title
const Title = styled.h1`
  font-size: 1.4rem;
  margin: 0;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 1rem; /* Reduce font size on mobile */
  }
`;

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <Title>Sorare Rivals Data</Title>
      <BuyMeABeerButton />
    </HeaderContainer>
  );
};

export default Header;
