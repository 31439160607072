export const LossIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 -6.10352e-05L8.30889e-05 16H16.0001V9.68575e-06L0 -6.10352e-05ZM6.20008 3.75998H4.22008V8.15998H6.20008V3.75998ZM6.20008 12.56V8.15998H4.22008V12.56H6.20008ZM7.10941 12.56L6.20008 10.36H10.8867L11.7961 12.56H7.10941Z"
      fill="#FF4E22"
    />
  </svg>
);
