import React from 'react';
import styled from 'styled-components';
import { Game } from '../App';
import fallbackImage from '../assets/shield_none-uVtR8SvS.png'; // Import the fallback image
import GameSkeleton from './GameSkeleton';

const GameItem = styled.div<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? '#444' : '#222')};
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #555;
  }

  @media (max-width: 768px) {
    padding: 10px; /* Reduce padding on mobile */
  }
`;

const GameHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 12px; /* Reduce font size on mobile */
  }
`;

const CompetitionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 24px;
    height: 16px;
    border-radius: 4px;
  }
`;

const GameTime = styled.div`
  font-size: 14px;
`;

const Teams = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 5px; /* Reduce gap on mobile */
  }
`;

const Team = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100px;

  img {
    width: 50px;
    height: auto;
    margin-bottom: 5px;
  }

  span {
    font-size: 12px;
    word-wrap: break-word;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    img {
      width: 40px;
      height: auto;
    }
    span {
      font-size: 10px; /* Reduce font size on mobile */
    }
  }
`;

const Vs = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ccc;
`;

const EmptyState = styled.p`
  text-align: center;
`;

interface GameListProps {
  isLoading?: boolean;
  games: Game[];
  selectedGame: Game | null;
  onGameSelect: (game: Game) => void;
}

const GameList: React.FC<GameListProps> = ({
  isLoading,
  games,
  selectedGame,
  onGameSelect,
}) => {
  if (!isLoading && games.length === 0) {
    return <EmptyState>No games found for this date.</EmptyState>;
  }

  return (
    <div>
      {isLoading
        ? Array.from({ length: 5 }).map((_, index) => (
            <GameSkeleton key={index} />
          ))
        : games.map((game) => (
            <GameItem
              key={game.id}
              onClick={() => onGameSelect(game)}
              selected={selectedGame?.id === game.id}
            >
              <GameHeader>
                <CompetitionInfo>
                  <img
                    loading="lazy"
                    src={game.game.competition.country.flagUrl}
                    alt={`${game.game.competition.country.name} flag`}
                  />
                  <span>{game.game.competition.name}</span>
                </CompetitionInfo>
                <GameTime>
                  {new Date(game.game.date).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </GameTime>
              </GameHeader>
              <Teams>
                <Team>
                  <img
                    loading="lazy"
                    src={game.game.homeTeam.pictureUrl || fallbackImage}
                    alt={`${game.game.homeTeam.name} logo`}
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = fallbackImage;
                    }}
                  />
                  <span>
                    {game.game.homeTeam.shortName || game.game.homeTeam.name}
                  </span>
                </Team>
                <Vs>Vs</Vs>
                <Team>
                  <img
                    loading="lazy"
                    src={game.game.awayTeam.pictureUrl || fallbackImage}
                    alt={`${game.game.awayTeam.name} logo`}
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = fallbackImage;
                    }}
                  />
                  <span>
                    {game.game.awayTeam.shortName || game.game.awayTeam.name}
                  </span>
                </Team>
              </Teams>
            </GameItem>
          ))}
    </div>
  );
};

export default GameList;
