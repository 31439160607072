export const InfoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#333"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      fill="#ffcc00"
      stroke="#fff"
      stroke-width="2"
    />

    <path d="M12 16v-4" stroke="#333" stroke-width="2" stroke-linecap="round" />
    <circle cx="12" cy="8" r="0.5" fill="#333" />
  </svg>
);
