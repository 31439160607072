export const DrawIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.000307262 0L0 16H16.0003V1.01626e-05L0.000307262 0ZM5.80028 3.75997H3.82029V8.15997H5.80028V3.75997ZM5.80028 3.75997L6.70962 5.95997H9.05628L8.14695 3.75998L5.80028 3.75997ZM5.80028 12.56V8.15997H3.82029V12.56H5.80028ZM5.80028 12.56L8.14695 12.56L9.05628 10.36H6.70962L5.80028 12.56ZM9.48962 10.36L8.58028 12.56H10.9269L11.8363 10.36L11.8349 10.36L12.7443 8.15998L11.8349 5.95997H9.48829L10.3976 8.15998L9.48829 10.36L9.48962 10.36ZM9.48962 5.95997L8.58028 3.75998H10.9269L11.8363 5.95997H9.48962Z"
      fill="white"
    />
  </svg>
);
