import styled from 'styled-components';
import fallbackImage from '../assets/shield_none-uVtR8SvS.png';
import injuredImage from '../assets/Injured.svg.png';
import { Player } from '../App';

const PlayerItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  color: #ffffff;

  img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }

  .player-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .flag-image {
      width: 24px;
      height: 16px;
      border-radius: 4px;
    }
  }

  .player-info {
    display: flex;
    flex-direction: column;
    flex: 1; /* Ensure player info takes full width on mobile */

    span {
      display: flex;
      gap: 4px;
      font-size: 14px;
    }

    .player-country {
      display: flex;
      align-items: center;
      gap: 5px;

      .injured {
        color: red;
      }

      img {
        width: 24px;
        height: 16px;
        border-radius: 4px;
      }
    }

    .tactic-value {
      font-weight: bold;
      color: #ffcc00;
    }

    .injury {
      height: 16px;
      width: 16px;
      border-radius: 14px;
    }

    .cap-value {
      position: relative;
      display: inline-block;
      width: 28px;
      height: 32px;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-weight: bold;
      }
    }

    .avg-mins {
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    flex-direction: row; /* Stack items vertically on mobile */
    align-items: flex-start; /* Align items to the start */
    margin-bottom: 24px;

    img {
      margin-bottom: 5px; /* Add space between image and info */
    }

    .player-info {
      span {
        font-size: 12px; /* Reduce font size on mobile */
      }
    }
  }
`;

const StartingLineupBadge = styled.span`
  background-color: #28a745; // A subtle green color
  color: white;
  padding: 2px 6px; // Smaller padding for a more subtle look
  border-radius: 8px; // Smaller border-radius for a less pronounced pill shape
  font-size: 0.75rem; // Reduced font size for a more subtle appearance
  margin-left: 8px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 500; // Slightly lighter font weight
`;

interface PlayerProps {
  player: Player;
  limitValue: string;
}

const PlayerComponent: React.FC<PlayerProps> = ({ player, limitValue }) => {
  const hasAverageMins = isFinite(
    player.player.stats.minutesPlayed / player.player.stats.appearances
  );

  return (
    <PlayerItem key={player.player.id}>
      <div className="player-image">
        <img
          loading="lazy"
          src={player.player.squaredPictureUrl || fallbackImage}
          alt={player.player.displayName}
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = fallbackImage;
          }}
        />
        <img
          loading="lazy"
          className="flag-image"
          src={player.player.country.flagUrl}
          alt={`${player.player.country.name} flag`}
        />
      </div>
      <div className="player-info">
        <span>
          <div className="player-country">
            <span
              className={
                player.player.activeInjuries[0]?.active ? 'injured' : ''
              }
            >
              {player.player.displayName}
            </span>

            {player.isInStartingLineup && (
              <StartingLineupBadge>Starting</StartingLineupBadge>
            )}
          </div>
        </span>
        <span>
          {player.player.position}
          {player.player.activeInjuries[0]?.active && (
            <>
              <img
                loading="lazy"
                className="injury"
                src={injuredImage}
                alt="injured"
              />
              <span style={{ color: 'red' }}>
                {player.player.activeInjuries[0]?.kind}
              </span>
            </>
          )}
        </span>
        <span className="tactic-value">
          Tactic Value: {player.tacticValue || 'N/A'} (
          {limitValue.toLowerCase().replace('_', ' ')})
        </span>
        {(!!player.player.stats.appearances ||
          player.player.stats.appearances === 0) && (
          <span>
            {new Date().getFullYear()}: Apps {player.player.stats.appearances}
            <span className="avg-mins">
              {hasAverageMins &&
                ` | Avg Mins ${Math.round(
                  player.player.stats.minutesPlayed /
                    player.player.stats.appearances
                )}`}
            </span>
          </span>
        )}
        <span className="cap-value">
          <svg
            width="28"
            height="32"
            viewBox="0 0 28 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.015 6.848A4 4 0 0 0 0 10.321V21.68a4 4 0 0 0 2.015 3.473l10 5.714a4 4 0 0 0 3.97 0l10-5.714A4 4 0 0 0 28 21.679V10.32a4 4 0 0 0-2.015-3.473l-10-5.714a4 4 0 0 0-3.97 0l-10 5.714Z"
              fill="#5974FF"
            />
          </svg>
          <span>{player.capValue}</span>
        </span>
      </div>
    </PlayerItem>
  );
};

export default PlayerComponent;
