import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const GameSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#444" highlightColor="#666">
      <div style={{ padding: '5px', marginBottom: '10' }}>
        <Skeleton height={120} style={{ borderRadius: '10px' }} />
      </div>
    </SkeletonTheme>
  );
};

export default GameSkeleton;
