import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 24px; */
`;

const ModalContent = styled.div`
  background-color: #333;
  color: #fff;
  padding: 0px 20px 20px;
  border-radius: 10px;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 4px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  z-index: 1000;
`;

interface ModalProps {
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ onClose, children }) => {
  return (
    <ModalOverlay>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <ModalContent>{children}</ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
