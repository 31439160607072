import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PlayerSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#444" highlightColor="#666">
      <div style={{ padding: '10px', marginBottom: '15px' }}>
        <Skeleton height={50} width={50} style={{ marginRight: '10px' }} />
        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
          <Skeleton height={20} width={150} style={{ marginBottom: '10px' }} />
          <Skeleton height={15} width={100} style={{ marginBottom: '5px' }} />
          <Skeleton height={15} width={80} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default PlayerSkeleton;
