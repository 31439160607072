import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import GameList from './components/GameList';
import PlayerList from './components/PlayerList';
import Modal from './components/Modal';
import { removeDuplicatePlayers } from './utils/removeDuplicates';
import Header from './components/Header';
import TacticInfoPopUp from './components/TacticInfoPopUp';
import { Refresh } from './assets/Refresh';

declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  margin-top: 12px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack the columns on top of each other on mobile */
    padding: 0 10px 10px; /* Reduce padding on smaller screens */
    margin-top: 0;
  }
`;

const CenteredDatePicker = styled.div`
  position: sticky;
  top: 0;
  background-color: #ffffff; /* Match the background color of your PlayersColumn */
  padding: 10px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  gap: 24px;
`;

const StyledDateInput = styled.input`
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #555;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  &::selection {
    background: none; /* Prevent highlighting */
  }

  &::-webkit-datetime-edit {
    color: inherit;
    background: none;
  }

  &::-webkit-datetime-edit-fields-wrapper {
    background-color: transparent;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-clear-button {
    display: none;
  }
`;

const RefreshButton = styled.div`
  cursor: pointer;
`;

const GamesColumn = styled.div`
  flex: 1;
  padding-right: 20px;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    padding-right: 0; /* Remove padding on mobile */
    padding-bottom: 10px;
  }
`;

const PlayersColumn = styled.div`
  flex: 2;
  padding-left: 20px;
  background-color: #333;
  border-radius: 10px;
  padding: 15px;
  color: #fff;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding-bottom: 20px;
  padding-top: 0;

  @media (max-width: 768px) {
    display: none;
    /* padding-top: 15px; */
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow filters to wrap if needed */
  gap: 10px; /* Add some space between the filters */
  margin-bottom: 15px; /* Reduce the bottom margin */
  position: sticky; /* Make the container sticky */
  top: 0; /* Stick the container to the top */
  background-color: #333; /* Match the background color of your PlayersColumn */
  z-index: 10; /* Ensure it stays above other content */
  padding: 10px; /* Optional: add padding to make it stand out */
  padding-top: 25px;
  box-shadow: 0px 6px 4px -4px rgba(0, 0, 0, 0.3); /* Bottom shadow */

  @media (max-width: 768px) {
    padding-top: 8px;
  }
`;

const TacticFilterLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 0.9rem; /* Make the label text smaller */
  font-weight: 600;
  margin-right: 5px; /* Add a small margin to the right of labels */
  align-self: center;
`;

const FilterLabel = styled.label`
  font-size: 0.9rem; /* Make the label text smaller */
  font-weight: 600;
  margin-right: 5px; /* Add a small margin to the right of labels */
  align-self: center;
`;

const FilterSelect = styled.select`
  padding: 5px; /* Reduce padding */
  font-size: 0.9rem; /* Make the select text smaller */
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: #555;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  /* Ensure the select boxes fit well */
  min-width: 100px;
`;

const TacticSelect = styled.select`
  padding: 5px; /* Reduce padding */
  font-size: 0.9rem; /* Make the select text smaller */
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: #555;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  /* Ensure the select boxes fit well */
  min-width: 100px;

  @media (max-width: 768px) {
    margin-right: 24px;
  }
`;

const FilterCheckbox = styled.input`
  margin-right: 5px; /* Make the checkbox smaller */
  transform: scale(0.8); /* Scale down the checkbox */
`;

const FilterCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 0.9rem; /* Make the checkbox label text smaller */
  font-weight: 600;
`;

export interface Team {
  lastFiveGames: {
    winner: {
      slug: string | null;
    };
  }[];
  pictureUrl: string;
  name: string;
  shortName: string;
  slug: string;
}

export interface Game {
  id: string;
  slug: string;
  game: {
    competition: {
      name: string;
      country: {
        name: string;
        flagUrl: string;
      };
    };
    date: string;
    awayTeam: Team;
    homeTeam: Team;
  };
}

export interface Player {
  capValue: number;
  tacticValue: number;
  isInStartingLineup?: boolean;
  player: {
    stats: {
      minutesPlayed: number;
      appearances: number;
    };
    activeNationalTeam: {
      slug: string;
    } | null;
    activeInjuries: {
      active: boolean;
      kind?: string;
    }[];
    country: {
      name: string;
      flagUrl: string;
    };
    activeClub?: {
      name: string;
      pictureUrl: string;
      slug: string;
    };
    rawPlayerGameScores: number[];
    displayName: string;
    squaredPictureUrl: string;
    id: string;
    position: string;
    slug: string;
    seasonAppearances: number;
  };
}

interface PlayerStat {
  averageStats: number;
  slug: string;
  id: string;
}

const tacticsOptions = [
  {
    label: 'Tiki taka (Accurate Pass)',
    value: 'ACCURATE_PASS',
    slug: 'tiki_taka',
  },
  {
    label: 'Gegenpressing (Duel won)',
    value: 'DUEL_WON',
    slug: 'gegenpressing',
  },
  {
    label: 'Park the bus (Effective clearance)',
    value: 'EFFECTIVE_CLEARANCE',
    slug: 'park_the_bus',
  },
  {
    label: 'Joga bonito (Won Contest)',
    value: 'WON_CONTEST',
    slug: 'joga_bonito',
  },
  {
    label: 'All out attack (Shot on goal)',
    value: 'ONTARGET_SCORING_ATT',
    slug: 'all_out_attack',
  },
  // Add more tactics here
];

const App: React.FC = () => {
  const getLocalDateString = () => {
    const now = new Date();
    const localYear = now.getFullYear();
    const localMonth = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const localDay = String(now.getDate()).padStart(2, '0');

    return `${localYear}-${localMonth}-${localDay}`;
  };

  const [games, setGames] = useState<Game[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(
    getLocalDateString()
  ); // Default to today
  const [filteredGames, setFilteredGames] = useState<Game[]>([]);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [selectedTactic, setSelectedTactic] = useState<{
    label: string;
    value: string;
    slug: string;
  }>({
    label: 'Tiki taka (Accurate Pass)',
    value: 'ACCURATE_PASS',
    slug: 'tiki_taka',
  });
  const [limitValue, setLimitValue] = useState<string>('LAST_15'); // Default to LAST_15
  const [players, setPlayers] = useState<Player[]>([]);
  const [thresholds, setThresholds] = useState<
    { slug: string; thresholds: { threshold: number; score: number }[] }[]
  >([]);
  const [selectedPosition, setSelectedPosition] = useState<string>(''); // State for position filtering
  const [showStartingLineup, setShowStartingLineup] = useState<boolean>(false); // New state for filtering by starting lineup
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gamesLoading, setGamesLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsModalOpen(false); // Close modal on desktop if window is resized to larger than 768px
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    fetchAllGames();
  }, []);

  useEffect(() => {
    const filtered = games.filter((game) => {
      // Convert game date from UTC to local time
      const localGameDate = new Date(game.game.date);

      // Correct the offset issue by using the correct date part in local time
      const localYear = localGameDate.getFullYear();
      const localMonth = String(localGameDate.getMonth() + 1).padStart(2, '0'); // Ensure two digits
      const localDay = String(localGameDate.getDate()).padStart(2, '0'); // Ensure two digits

      const formattedLocalGameDate = `${localYear}-${localMonth}-${localDay}`;

      // Compare with the selected date, which is already in local time
      return formattedLocalGameDate === selectedDate;
    });

    setFilteredGames(filtered);
  }, [games, selectedDate]);

  const fetchAllGames = async (noCache?: boolean) => {
    setGamesLoading(true);
    const response = await axios.post('/.netlify/functions/fetchSorareData', {
      query: `query GetRivalsMatchesGameQueryV2 {
        football {
          rivals {
            upcomingGames {
              id
              slug
              game {
                competition {
                  name
                  country {
                    name
                    flagUrl
                  }
                }
                date
                awayTeam {
                  lastFiveGames{
                    winner{
                      slug
                    }
                  }
                  pictureUrl
                  name
                  shortName
                  slug
                }
                homeTeam {
                  lastFiveGames{
                      winner{
                        slug
                      }
                  }
                  pictureUrl
                  name
                  shortName
                  slug
                }
              }
            }
          }
        }
      }`,
      noCache,
    });

    const games = response.data.data.football.rivals.upcomingGames;
    setGames(games);
    setGamesLoading(false);
  };

  const fetchPlayersForGame = useCallback(
    async (slug: string) => {
      setIsLoading(true);
      // Fetch draftable players
      const response = await axios.post('/.netlify/functions/fetchSorareData', {
        query: `
        query GetRivalsDraftablePlayers {
          football {
            rivals {
              game(slug: "${slug}") {
              lineupTactics {
                slug
                thresholds {
                  threshold
                  score
                }
              }
              formationKnown
              game{
                awayFormation{
                  startingLineup{
                    slug
                    id
                  }
                }
                homeFormation{
                  startingLineup{
                    slug
                    id
                  }
                }
              }
                cap
                draftablePlayers {
                  capValue
                  player {
                    stats(seasonStartYear:${new Date().getFullYear()}){
                      minutesPlayed
                      appearances
                    }
                    activeNationalTeam {
                      slug
                    }
                    activeInjuries{
                      active
                      kind
                    }
                    country {
                      name
                      flagUrl
                    }
                    activeClub {
                      name
                      pictureUrl
                      slug
                    }
                    rawPlayerGameScores(last: 10)
                    displayName
                    squaredPictureUrl
                    id
                    position
                    slug
                    seasonAppearances
                  }
                }
              }
            }
          }
        }
      `,
      });

      let players: Player[] =
        response.data.data.football.rivals.game.draftablePlayers;
      players = removeDuplicatePlayers(players);

      // Flatten and combine slugs from home and away formations
      const homeStartingLineupSlugs =
        response.data.data.football.rivals.game.game.homeFormation.startingLineup
          .flat()
          .map((player: { slug: string }) => player.slug);

      const awayStartingLineupSlugs =
        response.data.data.football.rivals.game.game.awayFormation.startingLineup
          .flat()
          .map((player: { slug: string }) => player.slug);

      const startingLineupSlugs = [
        ...homeStartingLineupSlugs,
        ...awayStartingLineupSlugs,
      ];

      // Mark players that are in the starting lineup
      players = players.map((player: Player) => ({
        ...player,
        isInStartingLineup: startingLineupSlugs.includes(player.player.slug),
      }));

      // Fetch tactic values for the draftable players
      const slugs = players.map((player) => player.player.slug);
      if (slugs.length > 0) {
        const tacticResponse = await axios.post(
          '/.netlify/functions/fetchSorareData',
          {
            query: `
          query GetPlayerTacticsScore {
            football {
              players(slugs:[${slugs.map((slug) => `"${slug}"`).join(',')}]) {
                averageStats(limit: ${limitValue}, type: ${
              selectedTactic.value
            })
                slug
                id
              }
            }
          }
        `,
          }
        );

        const playerStats = tacticResponse.data.data.football.players;

        // Combine the tactic values with the draftable players
        players = players.map((player) => {
          const stats = playerStats.find(
            (stat: PlayerStat) => stat.slug === player.player.slug
          );
          return {
            ...player,
            tacticValue: stats ? stats.averageStats : 0, // Assign tactic value, default to 0 if not found
          };
        });

        // Sort players by the tactic value
        players.sort((a, b) => (b.tacticValue || 0) - (a.tacticValue || 0));
      }

      const thresholds = response.data.data.football.rivals.game.lineupTactics;

      // Update the players state with the combined information
      setPlayers(players);
      setThresholds(thresholds);
      setIsLoading(false);
    },
    [limitValue, selectedTactic]
  );

  useEffect(() => {
    if (selectedGame) {
      fetchPlayersForGame(selectedGame.slug);
    }
  }, [selectedGame, selectedTactic, limitValue, fetchPlayersForGame]);

  // Filter players by selected position and starting lineup
  const filteredPlayers = players.filter((player) => {
    const matchesPosition = selectedPosition
      ? player.player.position === selectedPosition
      : true;
    const matchesStartingLineup = showStartingLineup
      ? player.isInStartingLineup
      : true;
    return matchesPosition && matchesStartingLineup;
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onGameSelect = useCallback((game: Game) => {
    setSelectedGame(game);

    if (window.innerWidth <= 768) {
      setIsModalOpen(true); // Open modal only on mobile
    } else {
      setIsModalOpen(false); // Ensure modal is closed on desktop
    }

    if (window.gtag) {
      window.gtag('event', 'select_game', {
        event_category: 'Game',
        event_label: game.slug,
        game_date: game.game.date,
        home_team: game.game.homeTeam.shortName,
        away_team: game.game.awayTeam.shortName,
        competition: game.game.competition.name,
        value: game.id,
      });
    }
  }, []);

  return (
    <Container>
      <Header />
      <Content>
        <GamesColumn>
          <CenteredDatePicker>
            <StyledDateInput
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <RefreshButton
              onClick={() => {
                fetchAllGames(true);
              }}
            >
              <Refresh />
            </RefreshButton>
          </CenteredDatePicker>
          <GameList
            isLoading={gamesLoading}
            games={filteredGames}
            selectedGame={selectedGame}
            onGameSelect={onGameSelect}
          />
        </GamesColumn>
        <PlayersColumn>
          <FiltersContainer>
            <TacticFilterLabel>
              <TacticInfoPopUp
                thresholds={
                  thresholds.find((thres) => thres.slug === selectedTactic.slug)
                    ?.thresholds
                }
              />
              Tactic:
            </TacticFilterLabel>
            <TacticSelect
              value={selectedTactic.value}
              onChange={(e) => {
                setSelectedTactic(
                  tacticsOptions.find(
                    (tactic) => tactic.value === e.target.value
                  ) || tacticsOptions[0]
                );

                if (window.gtag) {
                  window.gtag('event', 'select_tactic', {
                    event_category: 'Tactic',
                    event_label: selectedGame?.slug,
                    game_date: selectedGame?.game.date,
                    home_team: selectedGame?.game.homeTeam.shortName,
                    away_team: selectedGame?.game.awayTeam.shortName,
                    competition: selectedGame?.game.competition.name,
                    value: e.target.value,
                  });
                }
              }}
            >
              {tacticsOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TacticSelect>

            <FilterLabel>Range:</FilterLabel>
            <FilterSelect
              value={limitValue}
              onChange={(e) => {
                setLimitValue(e.target.value);

                if (window.gtag) {
                  window.gtag('event', 'select_limit_value', {
                    event_category: 'Limit',
                    event_label: selectedGame?.slug,
                    game_date: selectedGame?.game.date,
                    home_team: selectedGame?.game.homeTeam.shortName,
                    away_team: selectedGame?.game.awayTeam.shortName,
                    competition: selectedGame?.game.competition.name,
                    value: e.target.value,
                  });
                }
              }}
            >
              <option value="LAST_5">Last 5</option>
              <option value="LAST_15">Last 15</option>
              <option value="LAST_40">Last 40</option>
              <option value="SEASON">Season</option>
            </FilterSelect>

            <FilterLabel>Position:</FilterLabel>
            <FilterSelect
              value={selectedPosition}
              onChange={(e) => {
                setSelectedPosition(e.target.value);

                if (window.gtag) {
                  window.gtag('event', 'select_position', {
                    event_category: 'Position',
                    event_label: selectedGame?.slug,
                    game_date: selectedGame?.game.date,
                    home_team: selectedGame?.game.homeTeam.shortName,
                    away_team: selectedGame?.game.awayTeam.shortName,
                    competition: selectedGame?.game.competition.name,
                    value: e.target.value,
                  });
                }
              }}
            >
              <option value="">All Positions</option>
              <option value="Goalkeeper">Goalkeeper</option>
              <option value="Defender">Defender</option>
              <option value="Midfielder">Midfielder</option>
              <option value="Forward">Forward</option>
            </FilterSelect>

            <FilterCheckboxLabel>
              <FilterCheckbox
                type="checkbox"
                checked={showStartingLineup}
                onChange={(e) => {
                  setShowStartingLineup(e.target.checked);

                  if (window.gtag) {
                    window.gtag('event', 'show_starting_lineup', {
                      event_category: 'Starting',
                      event_label: selectedGame?.slug,
                      game_date: selectedGame?.game.date,
                      home_team: selectedGame?.game.homeTeam.shortName,
                      away_team: selectedGame?.game.awayTeam.shortName,
                      competition: selectedGame?.game.competition.name,
                      value: e.target.checked,
                    });
                  }
                }}
              />
              Show Starting Lineup Only (if available)
            </FilterCheckboxLabel>
          </FiltersContainer>
          <PlayerList
            players={filteredPlayers}
            selectedGame={selectedGame}
            limitValue={limitValue}
            isLoading={isLoading}
          />
        </PlayersColumn>
      </Content>
      {/* Modal for mobile view */}
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <FiltersContainer>
            <TacticFilterLabel>
              <TacticInfoPopUp
                isMobile
                thresholds={
                  thresholds.find((thres) => thres.slug === selectedTactic.slug)
                    ?.thresholds
                }
              />
              Tactic:
            </TacticFilterLabel>
            <TacticSelect
              value={selectedTactic.value}
              onChange={(e) => {
                setSelectedTactic(
                  tacticsOptions.find(
                    (tactic) => tactic.value === e.target.value
                  ) || tacticsOptions[0]
                );

                if (window.gtag) {
                  window.gtag('event', 'modal_select_tactic', {
                    event_category: 'Tactic',
                    event_label: selectedGame?.slug,
                    game_date: selectedGame?.game.date,
                    home_team: selectedGame?.game.homeTeam.shortName,
                    away_team: selectedGame?.game.awayTeam.shortName,
                    competition: selectedGame?.game.competition.name,
                    value: e.target.value,
                  });
                }
              }}
            >
              {tacticsOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TacticSelect>

            <FilterLabel>Range:</FilterLabel>
            <FilterSelect
              value={limitValue}
              onChange={(e) => {
                setLimitValue(e.target.value);

                if (window.gtag) {
                  window.gtag('event', 'modal_select_limit_value', {
                    event_category: 'Limit',
                    event_label: selectedGame?.slug,
                    game_date: selectedGame?.game.date,
                    home_team: selectedGame?.game.homeTeam.shortName,
                    away_team: selectedGame?.game.awayTeam.shortName,
                    competition: selectedGame?.game.competition.name,
                    value: e.target.value,
                  });
                }
              }}
            >
              <option value="LAST_5">Last 5</option>
              <option value="LAST_15">Last 15</option>
              <option value="LAST_40">Last 40</option>
              <option value="SEASON">Season</option>
            </FilterSelect>

            <FilterLabel>Position:</FilterLabel>
            <FilterSelect
              value={selectedPosition}
              onChange={(e) => {
                setSelectedPosition(e.target.value);

                if (window.gtag) {
                  window.gtag('event', 'modal_select_position', {
                    event_category: 'Position',
                    event_label: selectedGame?.slug,
                    game_date: selectedGame?.game.date,
                    home_team: selectedGame?.game.homeTeam.shortName,
                    away_team: selectedGame?.game.awayTeam.shortName,
                    competition: selectedGame?.game.competition.name,
                    value: e.target.value,
                  });
                }
              }}
            >
              <option value="">All Positions</option>
              <option value="Goalkeeper">Goalkeeper</option>
              <option value="Defender">Defender</option>
              <option value="Midfielder">Midfielder</option>
              <option value="Forward">Forward</option>
            </FilterSelect>

            <FilterCheckboxLabel>
              <FilterCheckbox
                type="checkbox"
                checked={showStartingLineup}
                onChange={(e) => {
                  setShowStartingLineup(e.target.checked);

                  if (window.gtag) {
                    window.gtag('event', 'modal_show_starting_lineup', {
                      event_category: 'Starting',
                      event_label: selectedGame?.slug,
                      game_date: selectedGame?.game.date,
                      home_team: selectedGame?.game.homeTeam.shortName,
                      away_team: selectedGame?.game.awayTeam.shortName,
                      competition: selectedGame?.game.competition.name,
                      value: e.target.checked,
                    });
                  }
                }}
              />
              Show Starting Lineup Only (if available)
            </FilterCheckboxLabel>
          </FiltersContainer>
          <PlayerList
            players={filteredPlayers}
            selectedGame={selectedGame}
            limitValue={limitValue}
            isLoading={isLoading}
          />
        </Modal>
      )}
    </Container>
  );
};

export default App;
