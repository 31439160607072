export const WinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.000307262 1.60038e-05L0 16L16.0003 16V0L0.000307262 1.60038e-05ZM4.16029 8.15999H5.48029L3.72029 3.75999H1.52029L3.28029 8.15999H1.96029L3.72029 12.56H7.31363L8.00696 10.8267L8.70029 12.56H12.2936L14.0536 8.15999H12.7336L14.4936 3.75999H12.2936L10.5336 8.15999H11.8536L10.497 11.5517L9.14029 8.15999H9.14429L10.9043 3.75999H8.70429L8.00929 5.49749L7.31429 3.75999H5.11429L6.87429 8.15999L5.51696 11.5517L4.16029 8.15999Z"
      fill="#33CE78"
    />
  </svg>
);
