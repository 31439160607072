export const Arrow = () => (
  <svg
    height="20px"
    width="20px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 240 240" // Adjusted viewBox for better fitting
    xmlSpace="preserve"
  >
    <g>
      <g>
        <polygon
          fill="#FFFFFF"
          stroke="#FFFFFF" // Adding stroke for boldness
          strokeWidth="10" // Adjust stroke width to make it bolder
          points="152.835,39.285 146.933,45.183 211.113,109.373 0,109.373 0,117.723 211.124,117.723 
			146.933,181.902 152.835,187.811 227.096,113.55"
        />
      </g>
    </g>
  </svg>
);
