import React, { useState } from 'react';
import styled from 'styled-components';
import { InfoIcon } from '../assets/InfoIcon';

interface PopupProps {
  isOpen: boolean;
  isMobile?: boolean;
}

interface InfoPopupProps {
  thresholds?: {
    threshold: number;
    score: number;
  }[];
  isMobile?: boolean;
}

const InfoPopupContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

const Popup = styled.div<PopupProps>`
  position: absolute;
  left: ${({ isMobile }) => (isMobile ? '140px' : '212px')};
  top: 36px;
  padding: 0 20px;
  transform: translateX(-50%);
  color: #000;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  z-index: 1000;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const Card = styled.div<Pick<PopupProps, 'isMobile'>>`
  border: 2px solid #ffcc00;
  background-color: #333;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  min-width: ${({ isMobile }) => (isMobile ? '240px' : '320px')};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OverList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const OverItem = styled.li`
  font-size: 1rem;
  color: #fff;
  margin-bottom: 5px;
`;

const PointsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;
`;

const PointItem = styled.li`
  font-size: 1rem;
  color: #00ff00;
  margin-bottom: 5px;
`;

const Legend = styled.div`
  font-size: 0.8rem;
  color: #888;
  text-align: left;
  margin-top: 10px;
`;

const InfoPopup: React.FC<InfoPopupProps> = ({ thresholds = [], isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseOver = () => {
    setIsOpen(true);
  };

  const handleMouseOut = () => {
    setIsOpen(false);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault(); // Prevent touch event from triggering mouse events
    setIsOpen(!isOpen);
  };

  if (!thresholds.length) {
    return null;
  }

  return (
    <InfoPopupContainer
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onTouchStart={handleTouchStart}
    >
      <InfoIcon />
      <Popup isOpen={isOpen} isMobile={isMobile}>
        <Card isMobile={isMobile}>
          <Content>
            <OverList>
              {thresholds.map(({ threshold }) => (
                <OverItem>
                  Over {threshold}{' '}
                  <span style={{ color: '#ffcc00', fontSize: '0.8rem' }}>
                    ({threshold / 4} per player*)
                  </span>
                </OverItem>
              ))}
            </OverList>
            <PointsList>
              {thresholds.map(({ score }) => (
                <PointItem>+{score}</PointItem>
              ))}
            </PointsList>
          </Content>
          <Legend>* Per player values exclude GK</Legend>
        </Card>
      </Popup>
    </InfoPopupContainer>
  );
};

export default InfoPopup;
