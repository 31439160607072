import styled from 'styled-components';

const Button = styled.a`
  display: inline-block;
  padding: 8px 20px;
  background-color: #ffdd00;
  color: #000;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  position: fixed;
  top: 4px;
  right: 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 8px 10px;
  }
`;

const BuyMeABeerButton = () => {
  return (
    <Button
      href="https://www.buymeacoffee.com/manuk"
      target="_blank"
      rel="noopener noreferrer"
    >
      🍺 Buy me a beer
    </Button>
  );
};

export default BuyMeABeerButton;
